* {
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Open Sans';
  font-size: 14px;
}

h1 {
  font-size: 1.5rem;
  }

h2 {
  font-size: 1.1rem;
}

h3 {
  font-size: 1.3rem;
  font-weight: bold;
}

h4 {
  font-size: 1.2rem;
}

.row {
  margin: 0;
}

ul {
  margin-left: 25px;
}

.startseite {
  font-family: 'Bree Serif';
}

.suchenseite {
  form {
    margin-bottom: 50px;
  }

  .form-inline {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .input-group {
      align-items: center;

      input {
        margin-right: 25px;
      }

      .buttonlink.buttonlink.buttonlink.buttonlink {
        font-size: 1rem;
        padding: 1px 20px;
      }
    }
  }

  ul {
    list-style-type: none;
  }
}

.text-right1 {
  text-align: right;
}

.spalte1,
.kommtweg {
  width: 19%;
}

.spalte2 {
  width: 33%;
  img {
    float: right;
  }
}

.spalte3 {
  width: 25%;
}

.startseiteparalaxbild {
  min-height: 550px;

  @media (max-width: 1000px){
    min-height: 450px;
  }

  @media (max-width: 860px){
    min-height: 350px;
  }

  @media (max-width: 715px){
    min-height: 300px;
    background-position-y: center!important;
  }

  @media (max-width: 435px){
    min-height: 200px;
    background-position-y: center!important;
  }
}

#sprechzeitmobile {
  bottom: -30px;
  position: absolute;
  right: 0px;
  width: 100%;
  height: auto;
}

.startseiteverstehenlogoswrap {
  .startseiteverstehenlogos {
    width: 100%;

    @media (max-width: 767px) {
      margin: 15px auto;
      width: 50%;
    }
  }
  @media (max-width: 767px) {
    text-align: center;
  }
}

.ueberunsstellogo {
  img {
    width: 100%;
  }
}

.ueberunsblock1 {
  .spalte.spalte.spalte {
    img {
      width: 100%;
    }
  }
}

.ansprechpartnercard {
  width: 30%;
  background: #fff;
  padding: 10px;
  margin-bottom: 3%;
  box-shadow: 2px 2px 4px #bbb;
}

.sondercard {
  width: 23%;
  padding: 10px;
  margin-bottom: 3%;

}


h1.startseite {
  font-size: 2.3rem;
}

h2.startseite {
  font-size: 1.7rem;
}

a,
a:hover,
a:visited {
  color: #649110;
}

a.anker {
  bottom: 80px;
  position: relative;
}

header {
  .row {
    margin-left: 0;
    margin-right: 0;
  }


  .menuwrap {
    background-color: #fff;
    padding-bottom: 5px;
    width: 100vw;

    img {
      margin-top: -25px;
      width: 200px;
      height: auto;
      transition: height linear .1s;
    }

    &.fixed {
      -webkit-box-shadow: -2px -2px 20px -2px rgba(0,0,0,0.68);
      -moz-box-shadow: -2px -2px 20px -2px rgba(0,0,0,0.68);
      box-shadow: -2px -2px 20px -2px rgba(0,0,0,0.68);
      left: 0;
      position: fixed;
      top: 0;
      z-index: 200;

      img {
        margin-bottom: -10px;
        margin-top: 8px;
        width: 150px;
      }
    }
  }

  .headernav {
    margin-bottom: -10px;
    margin-top: 10px;

    a {
      color: #fff;
      margin-right: 15px;
      text-decoration: none;
      display: inline-block;

      @media (max-width: 850px){
        background-color: rgba(125,125,125, 0.8);
        border-radius: 10px 0 10px 0;
        padding: 2px 8px;
      }

      &:before {
        content: '';
        background: url('../img/icon-mail.svg');
        background-size: cover;
        display: inline-block;
        height: 15px;
        margin-right: 7px;
        position: relative;
        top: 2px;
        width: 20px;
      }

      &:first-child:before {
        background: url('../img/icon-tel.svg');
        width: 15px;
      }

      &:last-child:before {
        background: url('../img/icon-suche.svg');
        width: 15px;
      }
    }
  }

  nav {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .hauptmenue.hauptmenue.hauptmenue {

      @media (max-width:991px){
        max-height: calc(100vh - 82px);
        overflow: auto;
      }

      li {
        a {
          border-bottom: 2px solid #BACE34;
          color: #58595B;
          font-weight: bold;
          font-size: 1.2rem;
          padding: 18px 0.5rem;
          text-transform: uppercase;

          &:hover,
          &.aktiv {
            border-bottom: 7px solid #BACE34;
            padding-bottom: 13px;
          }

          @media (max-width: 1250px) {
            font-size: 1rem;
          }

          @media (max-width: 1050px) {
            font-size: 0.9rem;
          }
        }

        .dropdown-menu {

          a {
            border-bottom: none;
            font-size: 1rem;
            font-weight: normal;
            padding: 5px 0.5rem;
            text-transform: none;

            &:hover,
            &.aktiv {
              color: #89B34D;
            }
          }
        }
      }
    }
  }
}

.hoversize {
  transition: all 1s ease;

  &:hover {
    transform: scale(1.1);
  }
}

.buttonlink.buttonlink.buttonlink {
  background-image: -webkit-linear-gradient(0deg, rgb(255, 255, 255) calc(100% - 10px), rgb(199, 217, 41) calc(100% - 10px));
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 2px solid #406b14;
  color: #406b14;
 /* font-family: 'Signika','Open Sans'; */
  font-family: 'Open Sans';
  font-size: 0.8rem;
  font-weight: 700;
  padding: 1px 25px 1px 15px;
  text-decoration: none;

  &:hover {
    background-image: -webkit-linear-gradient(0deg, rgb(199, 217, 41) calc(100% - 10px), rgb(255, 255, 255) calc(100% - 10px));
    color: #fff;
  }
}

.pgia-gallery-thumbnails {
  a {
    display: inline-block;
    height: 120px;
    margin-right: 15px;
   /* overflow: hidden; */
    width: auto;

    img {
      height: 120px;
      width: auto;
    }

    &:last-child {
      margin-right: 0px;
    }
  }
}

p.p-n {
  padding: 5px 10px;
}

p.m-o {
  margin: 0;
}

.bg-dg {
  background-color: #DDEAAF;
}

.bg-hg {
  background-color: #EAF2C8;
  padding: 10px;
}

table {
  td {
    padding-bottom: 15px;
  }
}

.ueberunsboxen {
  flex-wrap: wrap;

  > div {
    margin: 0.5% 0.5% 2%;
    width: 19%;
  }
}

.vewobabild {
  max-width: 250px;
  width: 100%;
}

#carousel2 {
  .carousel-item {
    overflow: hidden;
    padding-left: 8.33333%;

    img {
      width: 100%;


      &.stoerer {
        opacity: 0.5;
        height: auto;
        left: -40%;
        position: absolute;
        top: 70%;
        transition: all 2s ease;
        width: 20%;
      }
    }

    &.active {

      img.stoerer {
        opacity: 1;
        left: 4%;
      }
    }
  }
}

.card-header.card-header.card-header {
  background: #F6F9DD;
}

.tab-content .tab-pane {
  padding-top: 15px;
}

.pfeilhochwrap {
  left: 0;
  text-align: center;
  position: absolute;
  width: 100vw;
  z-index: 100;

  .pfeilhoch {
    bottom: 30px;
    cursor: pointer;
    position: relative;
    transition: all 0.2s ease;
    width: 100px;

    &:hover {
      transform: scale(1.2);
//      bottom: 40px;
    }
  }
}

.ansprechpartner {
  .ansprechpartnercard {
    margin-right: 2.6666%;
  }
}

footer {
  position: relative;
  z-index: 200;

  a,
  a:hover,
  a:visited {
    color: #d6d929;
  }

  h2 {
    color: #d6d929;
    font-style: italic;
    text-align: center;
  }

  .container {
    max-width: 1500px;
    width: 95%;
  }

  .flexi {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    img {
      max-width: 100%;
      width: 100%;
    }

    .adresse {
      margin-top: 30px;
      width: 24%;
    }

    .sprechtag {
      width: 24%;
    }

    .logo {
      width: 12%;
    }
  }
}

@media (max-width: 1100px) {
  .ueberunsboxen {
    > div {
      width: 24%;
    }
  }
}

@media (max-width: 768px) {
  .ueberunsboxen {
    > div {
      width: 32%;
    }
  }
}

@media (max-width: 608px) {
  .ueberunsboxen {
    > div {
      width: 49%;
    }
  }
}

@media (max-width: 450px) {
  .ueberunsboxen {
    > div {
      width: 100%;
    }
  }
}